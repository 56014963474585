<template>
  <div class="pp-scoring-profile__left">
    <div class="pp-scoring-profile__content-header">
      Current Types Score
    </div>
    <div class="pp-scoring-profile__search-wrap">
      <gl-input
        v-model="search"
        class="pp-scoring-profile__search-input"
        clearable
        data-cy="typeName"
        :height="40"
        label="Type name"
        name="type"
        placeholder="Search by a type name"
        @clear="resetSearch"
      />
      <button
        class="gl-button gl-button--dark gl-button--padder"
        data-cy="addTypeSearch"
        @click="loadData(true)"
      >
        Search
      </button>
    </div>
    <div class="pp-scoring-profile__content-wrap">
      <o-table
        backend-sorting
        class="pp-table"
        :data="typesData"
        :default-sort="[sortField, sortOrder]"
        :default-sort-direction="defaultSortOrder"
        hoverable
        :mobile-cards="false"
        sort-icon="chevron-up"
        @sort="onSort"
      >
        <o-table-column
          v-slot="props"
          field="name"
          label="Type Name"
          sortable
        >
          <div class="pp-scoring-profile__table-name">
            {{ props.row.name }}
          </div>
        </o-table-column>
        <o-table-column
          v-if="withDefScore"
          v-slot="props"
          field="score"
          label="Default Score"
          sortable
        >
          <div class="pp-scoring-profile__table-default-score">
            <div v-if="props.row.score === undefined">
              <GlScoreLevelChip
                v-if="!loading"
                :level="props.row.score ? props.row.score.toFixed(0) : 0"
                score
              />
            </div>

            <GlScoreLevelChip
              v-else-if="!loading"
              :level="props.row.score ? props.row.score.toFixed(0) : 0"
              score
            />
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="assignedScore"
          label="Assigned Score"
          sortable
          :visible="canEditScore && canResetScore"
        >
          <div class="pp-scoring-profile__table-assigned-score">
            <div v-if="props.row.assignedScore === undefined">
              --
            </div>
            <GlScoreLevelChip
              v-else-if="!loading"
              assigned-score
              :level="props.row.assignedScore ? props.row.score.toFixed(0) : 0"
            />
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="actions"
          label="Actions"
          :visible="canEditScore && canResetScore"
        >
          <div class="pp-scoring-profile__table-actions">
            <gl-icon
              v-tooltip.top="'Edit Type'"
              :height="20"
              name="pp-edit-black"
              :width="20"
              @click="editType(props.row.name, Number(props.row.assignedScore), props.row.status, props.row._id)"
            />
            <gl-icon
              v-tooltip.top="'Reset Score'"
              :height="20"
              name="pp-reset"
              :width="20"
              @click="resetScoreHandler(props.row)"
            />
          </div>
        </o-table-column>
        <template #empty>
          <div
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="pp-pagination-table">
        <div class="pp-pagination-table__left">
          <div class="pp-pagination-table__text">
            Rows per page:
          </div>
          <div class="pp-pagination-table__select">
            <vSelect
              v-model="perPage"
              class="select--top-direction"
              :clearable="false"
              :options="pagesOptions"
              :searchable="false"
              @input="countChange"
            />
          </div>
        </div>
        <div class="pp-pagination-table__right">
          <o-pagination
            v-if="typesData.length && totalPages > 1"
            :current.sync="currentPage"
            :per-page="perPage"
            :range-after="0"
            :range-before="0"
            :total="total"
            @change="pageChange"
          >
            <template #default="props">
              <div
                v-if="props.page.isCurrent"
                class="pp-pagination-table__pages"
              >
                {{ `${props.page.number} of ${totalPages}` }}
              </div>
            </template>
            <template #previous="props">
              <div class="pp-pagination-table__button-prev">
                <o-pagination-button
                  :page="props.page"
                >
                  <gl-menu-item
                    :disabled="props.page.disabled"
                    icon="left"
                    :icon-height="24"
                    :icon-width="24"
                    label=""
                    not-outline
                  />
                </o-pagination-button>
                <div class="pp-pagination-table__current">
                  {{ props.page.number + 1 }}
                </div>
              </div>
            </template>
            <template #next="props">
              <div class="pp-pagination-table__button-next">
                <o-pagination-button
                  :page="props.page"
                >
                  <gl-menu-item
                    :disabled="props.page.disabled"
                    icon="right"
                    :icon-height="24"
                    :icon-width="24"
                    label=""
                    not-outline
                  />
                </o-pagination-button>
              </div>
            </template>
          </o-pagination>
        </div>
      </div>
      <div
        v-show="loading"
        class="pp-scoring-profile__loader"
        style="z-index: 10"
      >
        <gl-loader />
      </div>
    </div>
    <EditTypeModal
      v-if="showEditModal"
      :id="editTypeId"
      v-model="showEditModal"
      :loading="loading"
      :score="editTypeScore"
      :status="editTypeStatus"
      :type="editTypeName"
      @change-score="changeScore"
      @close="showEditModal = false"
      @edit="updateType"
    />
    <confirmUpdateCalcModal
      v-if="showConfirmModal"
      v-model="showConfirmModal"
      :message="message"
      @close="showConfirmModal = false"
      @confirm="confirm"
    />
    <resetScoreModal
      v-if="showResetModal"
      v-model="showResetModal"
      :name="resetData.name"
      @close="showResetModal = false"
      @confirm="resetConfirm"
    />
  </div>
</template>

<script>
//Vuex
import {mapActions, mapState} from "vuex";

// Components
import vSelect from 'vue-select'
import GlIcon from '@/components/gl-icon'
import GlLoader from '@/components/gl-loader'
import GlMenuItem from '@/components/gl-menu-item'
import EditTypeModal from '../modals/EditTypeModal'
import GlScoreLevelChip from '@/components/gl-score-level-chip'
import resetScoreModal from "@/pages/scoring-profiles/modals/resetScoreModal.vue";
import confirmUpdateCalcModal from "@/pages/scoring/modals/confirmUpdateCalcModal";
import GlInput from "@/components/gl-input.vue";

// Utils
import { formatterRecalcApproveMessage } from "@/utils/text-formatter";
import appConfig from '@/utils/appConfig'

export default {
  name: 'TypesScoring',
  components: {
    GlInput,
    GlIcon,
    vSelect,
    GlLoader,
    GlMenuItem,
    EditTypeModal,
    resetScoreModal,
    GlScoreLevelChip,
    confirmUpdateCalcModal,
  },
  props: {
    profileId: {
      type: [Number, String],
      default: null
    },
    withDefScore: {
      type: Boolean,
      default: false
    },
    canEditScore: {
      type: Boolean,
      default: true
    },
    canResetScore: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      showConfirmModal: false,
      resetData: null,
      message: '',
      loading: false,
      pagesOptions: [5, 10, 20, 50, 100],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      defaultSortOrder: 'desc',
      sortField: 'score',
      sortOrder: 'asc',
      name: '',
      search: '',
      score: 0,
      allTypes: [],
      typesData: [],
      editTypeName: '',
      newType: '',
      newScore: 0,
      editTypeScore: 0,
      editTypeId: null,
      editTypeStatus: '',
      showEditModal: false,
      showResetModal: false,
      skipCheck: false,
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'currencyList', 'coinData']),
    appConfig() {
      return appConfig
    },
  },
  watch: {
    coinType: {
      handler() {
        this.loadData()
      },
      immediate: true
    },
    profileId: {
      handler() {
        this.loadData()
      },
      immediate: true
    }
  },
  created() {
    this.perPage = localStorage.getItem('type-scoring-per-page') || this.perPage
    localStorage.setItem('type-scoring-per-page', this.perPage)
  },
  async mounted() {
    await this.loadData()
     this.getTypes({ count: 1000 }).then(({ data: { typeData } }) => {
       this.allTypes = typeData?.map(type => type.name)
     })
  },
  methods: {
    formatterRecalcApproveMessage,
    ...mapActions({
      getTypes: 'types/getTypes',
      editTypesData: 'types/editType',
      resetScoreType: 'types/resetScoreType',
    }),
    resetScoreHandler(data) {
      this.showResetModal = true
      this.resetData = data
    },
    resetConfirm(name) {
      const requestedCoins = appConfig.VUE_APP_MULTI_LABELING ? this.currencyList : [this.coinData]

      requestedCoins.forEach((coin) => {
        this.resetScoreType({ name, profileId: this.profileId, id: this.resetData._id, baseUrl: coin.profilesServiceUrl }).then((success, data) => {
          if (success) {
            this.showResetModal = false
            this.loadData()
          } else {
            this.$toasted.global.error({ message: `${data.message}`})
          }
        })
      })
    },
    confirm() {
      this.showEditModal = false
      this.skipCheck = true
      this.updateType(this.newType, this.newScore, this.editTypeId)
    },
    forbidEdit(type) {
      if (!type.status) return false
      return type.status && type.status.includes('forbidEdit')
    },
    forbidDeleteAndRename(type) {
      if (!type.status) return false
      return type.status.includes('forbidDeleteAndRename')
    },
    handleScoring(score) {
      if (score && Number(score) > 100) {
        this.score = 100
      }
    },
    resetSearch() {
      this.search = ''
      this.loadData()
    },
    changeScore(score) {
      this.editTypeScore = score
    },
    editType(name, score, status = '', id) {
      this.editTypeName = name
      this.editTypeId = id
      this.editTypeScore = Number(score)
      this.editTypeStatus = status
      this.showEditModal = true
    },
    updateType(name, score, id) {
      this.message = ''
      this.newType = ''
      this.newScore = 0
      this.loading = true

      const requestedCoins = appConfig.VUE_APP_MULTI_LABELING ? this.currencyList : [this.coinData]

      requestedCoins.forEach((coin) => {
        this.editTypesData({ name: this.editTypeName, newName: name, score: Number(score), skipCheck: this.skipCheck, profileId: this.profileId, isLabeling: false, id, baseUrl: coin.profilesServiceUrl }).then(({ success, data }) => {
          if (success) {
            this.editTypeName = ''
            this.editTypeScore = ''
            this.showEditModal = false
            this.showConfirmModal = false
            this.skipCheck = false
            this.loadData()
          } else {
            this.newType = name
            this.newScore = score
            if (data.showPopup || data?.response?.messageData) {
              this.message = this.formatterRecalcApproveMessage ? this.formatterRecalcApproveMessage(data?.response?.messageData) : data.message
              this.showConfirmModal = true
            } else this.$toasted.global.error({ message: `${data.message}`})
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },
    loadData(isNewSearch = false) {
      if (isNewSearch) {
        this.currentPage = 1
      }
      this.$emit('loading', this.loading)
      this.loading = true
      const sendParams = this.formattedSendData()
      this.getTypes(sendParams).then(({ data: { total, typeData } }) => {
        this.typesData = typeData || []
        this.total = total
        this.totalPages = Math.ceil(this.total / this.perPage)
        this.loading = false
        this.$emit('loading', this.loading)
      })
    },
    onSort(field, order) {
      this.sortOrder = order
      this.sortField = field
      this.loadData()
    },
    formattedSendData() {
      this.perPage = localStorage.getItem('type-scoring-per-page') || this.perPage

      return {
        sortField: this.sortField || undefined,
        count: this.perPage,
        search: this.search,
        profileId: this.profileId,
        skip: (this.currentPage - 1) * this.perPage,
        isLabeling: true,
        sortOrder: this.sortOrder || undefined
      }
    },
    countChange() {
      localStorage.setItem('type-scoring-per-page', this.perPage)
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
  }
}
</script>

<style>


@media (max-width: 767px) {
  
}
</style>
